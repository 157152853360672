// App.js
import { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [competition, setCompetition] = useState([]);
  const [participant, setParticipant] = useState([]);
  const [score, setScore] = useState([]);

  const fetchCompetition = () => {
    fetch(`https://polescores-backend.wuokko.org/pole-scores/competition`)
      .then((response) => response.json())
      .then((actualData) => {
        console.log(actualData);
        setCompetition(actualData.data);
        console.log(competition[0]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetchParticipant = () => {
    fetch(`https://polescores-backend.wuokko.org/pole-scores/participant`)
      .then((response) => response.json())
      .then((actualData) => {
        console.log(actualData);
        setParticipant(actualData.data);
        console.log(participant[0]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const fetchScore = () => {
    fetch(`https://polescores-backend.wuokko.org/pole-scores/score`)
      .then((response) => response.json())
      .then((actualData) => {
        console.log(actualData);
        setScore(actualData.data);
        console.log(score[0]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  /*  const getParticipantById = (id) => {
    console.log(id);
     participant.filter(obj => {
      return this.props.name === obj.name;
    }).map((obj, idx) => {
        return (
            <p key={idx}>
                        Name: {obj.name} < br/>
                        Email: {obj.email} <br />
                        Flavor: {obj.flavor} <br />
                        STR: {obj.str} <br />
                        AGI: {obj.agi} <br />
                        INT: {obj.int} <br />
                        STA: {obj.sta} <br />
            </p>
        );
    });
  } */

  const getParticipantById = (id) => {
    console.log(id);
    const found = participant.find(element => element.id == id);
    console.log(found);
    return found;

  }

  const getParticipantName = (id) => {
    const found = getParticipantById(id);
    return found.first_name + " " + found.last_name;

  }

  useEffect(() => {
    fetchCompetition();
    fetchParticipant();
    fetchScore();
  }, []);

  return (
    <div className="App">
      <tbody>
        <tr>
          <th>Name</th>
          <th>Artistic</th>
          <th>Execution</th>
          <th>Difficulty</th>
          <th>Head Judge</th>
          <th>Final Score</th>
        </tr>
        {score.map((item, index) => (
          <tr key={index}>
            <td>{getParticipantName(item.participant)}</td>
            <td>{item.artistic}</td>
            <td>{item.execution}</td>
            <td>{item.difficulty}</td>
            <td>{item.head_judge}</td>
            <td>{item.final_score}</td>
          </tr>
        ))}
      </tbody>
    </div>
  );
}

export default App;